import classNames from 'classnames'
import React from 'react'
import { ChevronDown } from 'react-feather'
import OutsideClickHandler from 'react-outside-click-handler'

export const GRADES = [
  { value: 'Kindergarten', label: 'Kindergarten' },
  { value: 'Grade 1', label: 'Grade 1' },
  { value: 'Grade 2', label: 'Grade 2' },
  { label: 'Grade 3', value: 'Grade 3' },
  { value: 'Grade 4', label: 'Grade 4' },
  { value: 'Grade 5', label: 'Grade 5' },
  { value: 'Grade 6', label: 'Grade 6' },
  { value: 'Grade 7', label: 'Grade 7' },
  { value: 'Grade 8', label: 'Grade 8' },
  { value: 'Grade 9', label: 'Grade 9' },
  { value: 'Grade 10', label: 'Grade 10' },
  { value: 'Grade 11', label: 'Grade 11' },
  { value: 'Grade 12', label: 'Grade 12' },
  { value: 'Undergraduate', label: '12th Pass' },
]

export type GradeType = {
  value: string
  label: string
}

type Props = {
  disabled?: boolean
  errorText?: string
  handleGradeSelect: (grade: GradeType) => void
}
const GradeSelect = ({
  disabled = false,
  errorText,
  handleGradeSelect,
}: Props) => {
  const [showOptions, setShowOptions] = React.useState(false)
  const [selectedGrade, setSelectedGrade] = React.useState<GradeType>({
    value: '',
    label: '',
  })

  const handleSelect = (grade: GradeType) => {
    setSelectedGrade(grade)
    setShowOptions(false)
    handleGradeSelect(grade)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
      <div className="relative w-full select-none">
        <div
          className={classNames(
            'flex items-center justify-between text-grey',
            'rounded px-4 py-2',
            {
              'bg-white cursor-pointer border border-grey-500 hover:border hover:border-orange-500': !disabled,
              'bg-grey-300 text-grey-700 border border-grey-500': disabled,
            }
          )}
          onClick={() => !disabled && setShowOptions(!showOptions)}
        >
          {selectedGrade.label || 'Select grade/class'}
          <ChevronDown size={15} />
        </div>

        {showOptions && (
          <div
            className={classNames(
              'absolute top-0 left-0 w-full mt-12',
              'bg-white rounded border border-grey-500 h-80 overflow-y-scroll'
            )}
          >
            {GRADES.map((grade, i) => (
              <div
                key={i}
                className={classNames('cursor-pointer ', {
                  'bg-orange-500 text-white':
                    selectedGrade.value === grade.value,
                  'hover:bg-orange-200': selectedGrade.value !== grade.value,
                })}
                onClick={() => handleSelect(grade)}
              >
                <p className="px-4 py-2">{grade.label}</p>
              </div>
            ))}
          </div>
        )}
        {errorText && (
          <div className="py-2 text-sm text-red-200 font-400">{errorText}</div>
        )}
      </div>
    </OutsideClickHandler>
  )
}

export default GradeSelect
