import PhoneInput from 'react-phone-input-2'
import useDetectCountry from '@hooks/useDetectCountry'
import React, { useEffect, useState } from 'react'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import MsgTrans from '@components/common/i18n/message-trans'
import Tooltip from '@components/common/tooltip'
import { Info } from 'react-feather'
import { t } from '@lingui/macro'

type Props = {
  id: string
  name: string
  label?: string | LinguiJSMessageDescriptor
  helpText?: string
  errorText?: string
  errorList?: Array<string>
  autoFocus?: boolean
  placeholder?: string
  required?: boolean
  disabled?: boolean
  value: any
  onChange: (value: string) => void
  handleEnterKeyPress?: () => void
  onKeyUp?: (e: React.FormEvent<HTMLInputElement>) => void
  countryCode?: string
  isRightToLeftPage?: boolean
} & typeof defaultProps

const defaultProps = {
  label: '',
  helpText: '',
  errorText: '',
  errorList: [],
  placeholder: '',
  required: false,
  disabled: false,
}

const CustomPhoneInput = (props: Props): JSX.Element => {
  const {
    value,
    onChange,
    label,
    id,
    name,
    required,
    autoFocus,
    placeholder,
    helpText,
    errorText,
    errorList,
    handleEnterKeyPress,
    disabled,
    countryCode: countryCodeFromProps,
    isRightToLeftPage,
  } = props

  const { countryCode: countryCodeFromHook } = useDetectCountry({
    isLowerCase: true,
  })

  // If the phone field is to be populated then skip setting country code
  // Because populated data will already contain the country code
  const getCountryCode = (value: string) =>
    value ? undefined : countryCodeFromProps || countryCodeFromHook

  const [countryCode, setCountryCode] = useState<string>(getCountryCode(value))

  // There is a delay between rendering and resolving of country code from hook
  // Hence we will update the country code once it is resovled from hook
  useEffect(() => {
    setCountryCode(getCountryCode(value))
  }, [countryCodeFromHook])

  return (
    <div className="flex flex-col space-y-1">
      {label && (
        <div className="text-grey text-md font-600 flex items-center">
          <MsgTrans msg={label} />
          <Tooltip
            className="ml-2"
            content={t`If you do not have a WhatsApp enabled phone number, then you can register with any other personal phone number.`}
            showOnHover
          >
            <Info className="cursor-pointer text-grey-700" size={16} />
          </Tooltip>
        </div>
      )}
      <PhoneInput
        containerClass="font-sans text-base"
        inputClass={`border border-grey-500 w-full rounded py-3 text-grey leading-tight focus:outline-none ${
          isRightToLeftPage ? 'rtl-phone-input pl-4 pr-12' : 'pl-16 pr-4'
        }`}
        searchStyle={{
          marginBottom: '2px',
        }}
        buttonClass={isRightToLeftPage && 'rtl-input'}
        enableSearch
        country={countryCode}
        value={value}
        countryCodeEditable={false}
        onChange={onChange}
        onEnterKeyPress={handleEnterKeyPress}
        masks={{ in: '..... .....' }}
        placeholder={placeholder}
        inputProps={{
          id: id,
          name: name,
          required: required,
          autoFocus: autoFocus,
        }}
        disabled={disabled}
      />
      {helpText && <div className="text-sm text-grey font-400">{helpText}</div>}
      {errorText && (
        <div className="text-sm text-red-200 font-400">{errorText}</div>
      )}
      {errorList &&
        errorList.map((val, idx) => (
          <div className="text-sm text-red-200 font-400" key={idx}>
            {val}
          </div>
        ))}
    </div>
  )
}

CustomPhoneInput.defaultProps = defaultProps
export default React.memo(CustomPhoneInput)
