import dynamic from 'next/dynamic'
import { RatelimitErrorModalProps } from '@components/auth/ratelimit-error-modal'
import React from 'react'
const RatelimitErrorModal = dynamic(() =>
  import('@components/auth/ratelimit-error-modal')
)

/** i18n support added */
const RatelimitErrorModalDynamic = (props: RatelimitErrorModalProps) => (
  <>{props.isOpen && <RatelimitErrorModal {...props} />}</>
)

export default React.memo(RatelimitErrorModalDynamic)
