export const TRACKED_PAGES = {
  FIRST_CLASS_BOOKED: 'First class booked',
  FIRST_CLASS_COMPLETED: 'First class completed',
  FREE_CLASS_COMPLETED: 'Free class completed',
  COURSE_PURCHASED: 'Course purchased',
}

export const FB_EVENTS = {
  SIGNUP_COMPLETED: 'SignupCompleted',
  FIRST_CLASS_BOOKED: 'FirstClassBooked',
  FIRST_CLASS_COMPLETED: 'FirstClassCompleted',
  FREE_CLASS_COMPLETED: 'FreeClassCompleted',
  COURSE_PURCHASED: 'CoursePurchased',
  NCO_HACKATHON_REGISTRATION_COMPLETED: 'NCOHackathonRegistrationCompleted',
  HPE_CODEBATTLE_REGISTRATION_COMPLETED: 'HpeCodeBattleRegistrationCompleted',
}

export const TRACKED_ONBOARDING_FLOWS = {
  GAMIFICATION: 'Gamification onboarding modal state',
  DASHBOARD_INTRODUCTION: 'Onboarding intro of dashboard state',
}
