import MsgTrans from '@components/common/i18n/message-trans'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { HasLaptopFieldType } from '@lib/types/geo-lp'

type Props = {
  onChange: (elem: React.FormEvent<HTMLInputElement> | string) => void
  error: string
  hasLaptopFieldLabels?: HasLaptopFieldType<string | LinguiJSMessageDescriptor>
}
const HasLaptopField = ({
  onChange,
  error,
  hasLaptopFieldLabels,
}: Props): JSX.Element => (
  <div className="lg:hidden">
    <p className="mb-2 text-base text-grey">
      <MsgTrans msg={hasLaptopFieldLabels?.label} />
    </p>
    <div className="flex space-x-5 text-grey">
      <div className="flex items-center space-x-1">
        <input
          type="radio"
          name="hasLaptop"
          id="yes"
          className="cursor-pointer"
          value="yes"
          onChange={onChange}
        />
        <label htmlFor="yes">
          <MsgTrans msg={hasLaptopFieldLabels?.option1} />
        </label>
      </div>
      <div className="flex items-center space-x-1">
        <input
          type="radio"
          name="hasLaptop"
          id="no"
          className="cursor-pointer"
          value="no"
          onChange={onChange}
        />
        <label htmlFor="no">
          <MsgTrans msg={hasLaptopFieldLabels?.option2} />
        </label>
      </div>
    </div>
    {error && (
      <div className="text-sm leading-4 text-red-200 font-400">{error}</div>
    )}
  </div>
)

export default HasLaptopField
