import HasLaptopField from '@components/book-trial-class/has-laptop'
import CustomPhoneInput from '@components/book-trial-class/phone-input'
import classNames from 'classnames'
import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import TermsLabelComponent from '@components/book-trial-class/terms-label'
import { HasLaptopFieldType } from '@lib/types/geo-lp'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import MsgTrans from '@components/common/i18n/message-trans'
import { childGradeOptions } from '@lib/enums/registration-form'
import { Trans, t } from '@lingui/macro'
import { Icon } from '@components/common/icons'
import { GradeType } from '@components/common/forms/grade-select'
import minimatch from 'minimatch'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { FastlyImage } from '@components/common/image'
const DynamicReactSelectGrade = dynamic(() =>
  import('@components/common/forms/select')
)
const DynamicCustomGradeSelect = dynamic(() =>
  import('@components/common/forms/grade-select')
)

const FORM_SHADOW = {
  boxShadow:
    '0px 0.9px 3.2px rgba(0, 0, 0, 0.028), 0px 2.5px 8.8px rgba(0, 0, 0, 0.04), 0px 6px 21.1px rgba(0, 0, 0, 0.052), 0px 20px 70px rgba(0, 0, 0, 0.08)',
}

type Props = {
  phone: string
  name: string
  email: string
  role: string
  childName: string
  childGrade: { value: string; label: string }
  childGender: { value: string; label: string }
  hasLaptop: boolean
  loading: boolean
  errors: any
  LOGGEDIN_PROMO_PAGE: boolean
  handleInputChange: (elem: FormEvent<HTMLInputElement> | string) => void
  handlePhoneInputChange: (value: string) => void
  handleSelectChange: (
    id: string,
    obj: {
      value: string
      label: string
    }
  ) => void
  handleTrialBookingFormSubmit: (e: FormEvent<HTMLFormElement>) => void
  ctaText?: string | LinguiJSMessageDescriptor
  termsAndConditions?: string
  countryCode?: string
  shouldHaveFormShadow?: boolean
  formHeading?: string
  phoneFieldLabel?: string | LinguiJSMessageDescriptor
  hasLaptopFieldLabels?: HasLaptopFieldType<string | LinguiJSMessageDescriptor>
  isRightToLeftPage?: boolean
  handleGradeSelect: (grade: GradeType) => void
  isAutoRegister?: boolean
  isMathTrial?: boolean
}
const ONE_SECOND_IN_MILLISECONDS = 1000
const DELAY_BEFORE_REDIRECT_IN_MILLISECONDS = 10000

export const BookTrialClassForm = ({
  phone,
  email,
  childGrade,
  loading,
  errors,
  LOGGEDIN_PROMO_PAGE,
  handleInputChange,
  handlePhoneInputChange,
  handleSelectChange,
  handleTrialBookingFormSubmit,
  ctaText = t`Try a free class`,
  termsAndConditions,
  countryCode,
  shouldHaveFormShadow = false,
  formHeading = '',
  phoneFieldLabel,
  hasLaptopFieldLabels,
  isRightToLeftPage,
  handleGradeSelect,
  isAutoRegister,
  isMathTrial,
}: Props) => {
  const router = useRouter()
  const formRef = React.createRef<HTMLFormElement>()
  const [hideform, setHideForm] = useState(false)
  const [timeLeft, setTimeLeft] = useState(
    DELAY_BEFORE_REDIRECT_IN_MILLISECONDS
  )

  // Focus phone input on page load
  useEffect(() => document.getElementById('phone').focus(), [])

  const fieldErrors = useMemo(() => {
    const phone = errors?.phone
    const childGrade = errors?.childGrade
    const hasLaptop = errors?.hasLaptop
    setHideForm(false)
    return { phone, childGrade, hasLaptop }
  }, [errors])

  useEffect(() => {
    if (isAutoRegister && formRef.current) {
      setHideForm(true)
      const timer = setTimeout(() => {
        document.getElementById('submitBtn')?.click()
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [isAutoRegister, formRef.current])

  useEffect(() => {
    import('@common/utils').then(
      ({ flattenErrorsDictValues, showErrorAlert }) => {
        const formErrors = errors
        delete formErrors.phone
        delete formErrors.childGrade
        delete formErrors.hasLaptop

        const flattenedErrors = flattenErrorsDictValues(formErrors)
        if (flattenedErrors.length > 0)
          showErrorAlert({ text: flattenedErrors.join('\n') })
      }
    )
  }, [errors])

  useEffect(() => {
    setTimeout(() => {
      if (timeLeft > 0) setTimeLeft(timeLeft - 100)
      if (timeLeft === 0) {
        return
      }
    }, 100)
  }, [timeLeft])

  useEffect(() => {
    if (timeLeft === 0 && !loading) {
      setHideForm(false)
    }
  }, [timeLeft, loading])

  // Only shows custom grade select component on lp pages for LCP improvement
  // TODO: add this component in every form replacing the `react-select` framework
  const shouldShowCustomGradeComponent = () => {
    const routes = ['/lp/**', '/book-trial-class', '/book-trial-class/**']
    const path = router?.asPath
    return routes.some((glob) => minimatch(path, glob))
  }

  return (
    <>
      {hideform && (
        <div className="flex flex-col max-w-sm px-8 lg:max-w-lg text-center lg:text-left">
          <FastlyImage
            src="/images/trial-class/track-booking/penguin.svg"
            alt="Penguin"
            className="h-40 lg:h-96 w-auto"
            width={100}
            height={218}
          />
          <p className="text-orange text-lg md:text-3xl font-600">
            <Trans>Registration in progress</Trans>
          </p>
          <p className="text-grey">
            <Trans>We will take you to the dashboard in few seconds</Trans>
          </p>
          <div className="mt-1">
            <div className="bg-grey-400 h-5 w-full rounded-2xl">
              <div
                className="h-full bg-orange rounded-2xl transform transition-all duration-100 ease-in"
                style={{
                  minWidth: '5%',
                  width: `${
                    10 *
                    ((DELAY_BEFORE_REDIRECT_IN_MILLISECONDS - timeLeft) /
                      ONE_SECOND_IN_MILLISECONDS)
                  }%`,
                }}
              />
            </div>
          </div>
        </div>
      )}

      <form
        ref={formRef}
        className={`z-10 ${
          hideform ? 'hidden' : 'flex'
        } flex-col w-full px-3 py-8 space-y-4 bg-white rounded-lg lg:px-5 lg:w-108`}
        style={shouldHaveFormShadow ? FORM_SHADOW : {}}
        onSubmit={handleTrialBookingFormSubmit}
      >
        {!formHeading && (
          <p className="text-center text-grey">
            {t`Hurry Up! The slots are filling fast`}
          </p>
        )}
        {formHeading && (
          <p className="text-4xl leading-tight text-grey lg:text-5xl font-600">
            {formHeading}
          </p>
        )}
        {/* This hidden email input field allows hubspot to capture form submission */}
        {/* https://community.hubspot.com/t5/APIs-Integrations/Hidden-Input-Fields-not-showing-up-on-into-collected-forms/m-p/223655/highlight/true#M2551 */}
        <div
          className="absolute w-0 h-0 pointer-events-none"
          style={{
            visibility: 'hidden',
            zIndex: -1,
            top: '-9999px',
            left: '-9999px',
          }}
        >
          <input
            id="email"
            name="email"
            type="email"
            placeholder="you@mail.com"
            value={email}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <CustomPhoneInput
          value={phone}
          onChange={handlePhoneInputChange}
          label={phoneFieldLabel as any}
          id="phone"
          name="phone"
          required={true}
          autoFocus={false}
          placeholder={t({
            id: 'register.phone.label',
            message: `Enter your mobile number`,
          })}
          errorText={fieldErrors?.phone}
          disabled={LOGGEDIN_PROMO_PAGE}
          countryCode={countryCode}
          isRightToLeftPage={isRightToLeftPage}
        />

        {!shouldShowCustomGradeComponent() && (
          <div className="flex flex-col gap-y-4 lg:gap-y-1 lg:flex-row lg:gap-x-4">
            <div className="w-full">
              <DynamicReactSelectGrade
                id="childGrade"
                value={childGrade}
                handleSelectChange={handleSelectChange}
                options={childGradeOptions}
                errorText={fieldErrors?.childGrade}
                disabled={LOGGEDIN_PROMO_PAGE}
                isSearchable={false}
                required
              />
            </div>
          </div>
        )}

        {shouldShowCustomGradeComponent() && (
          <div className="flex flex-col gap-y-4 lg:gap-y-1 lg:flex-row lg:gap-x-4">
            <div className="w-full">
              <DynamicCustomGradeSelect
                handleGradeSelect={handleGradeSelect}
                errorText={fieldErrors?.childGrade}
              />
            </div>
          </div>
        )}

        {!LOGGEDIN_PROMO_PAGE && (
          <HasLaptopField
            onChange={handleInputChange}
            error={fieldErrors?.hasLaptop}
            hasLaptopFieldLabels={hasLaptopFieldLabels}
          />
        )}
        <div>
          <div className="my-4">
            <button
              id="submitBtn"
              className={classNames('btn btn-orange w-full py-3 text-lg', {
                'opacity-50 cursor-not-allowed': loading,
              })}
            >
              <MsgTrans msg={ctaText} />
            </button>
          </div>
          <div className="flex items-start justify-center my-3 text-grey">
            <Icon name="Edit3" size={16} className="text-orange mr-2 mt-1" />
            {isMathTrial && (
              <p className="text-sm">{t`Register now, grab your free slot for math class!`}</p>
            )}
            {!isMathTrial && (
              <>
                {' '}
                {!formHeading && (
                  <p className="text-sm">{t`1000+ slots booked in last 24 hours`}</p>
                )}
                {formHeading && (
                  <p className="text-md">{t`Register now, grab your free slot for coding class!`}</p>
                )}
              </>
            )}
          </div>
          {termsAndConditions ? (
            <p
              className="text-sm text-grey-800"
              dangerouslySetInnerHTML={{ __html: termsAndConditions }}
            ></p>
          ) : (
            <TermsLabelComponent />
          )}
        </div>
      </form>
    </>
  )
}

export default React.memo(BookTrialClassForm)
