import { defineMessage } from '@lingui/macro'

export const childGradeOptions = [
  { value: 'Kindergarten', label: defineMessage({ message: 'Kindergarten' }) },
  { value: 'Grade 1', label: defineMessage({ message: 'Grade 1' }) },
  { value: 'Grade 2', label: defineMessage({ message: 'Grade 2' }) },
  { value: 'Grade 3', label: defineMessage({ message: 'Grade 3' }) },
  { value: 'Grade 4', label: defineMessage({ message: 'Grade 4' }) },
  { value: 'Grade 5', label: defineMessage({ message: 'Grade 5' }) },
  { value: 'Grade 6', label: defineMessage({ message: 'Grade 6' }) },
  { value: 'Grade 7', label: defineMessage({ message: 'Grade 7' }) },
  { value: 'Grade 8', label: defineMessage({ message: 'Grade 8' }) },
  { value: 'Grade 9', label: defineMessage({ message: 'Grade 9' }) },
  { value: 'Grade 10', label: defineMessage({ message: 'Grade 10' }) },
  { value: 'Grade 11', label: defineMessage({ message: 'Grade 11' }) },
  { value: 'Grade 12', label: defineMessage({ message: 'Grade 12' }) },
  { value: 'Undergraduate', label: defineMessage({ message: '12th Pass' }) },
]
